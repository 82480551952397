import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from "../services/auth"
import Flags from '../data/raw/flags';

const AllAccessRoute = ({ component: Component, location, prevLocation, ...rest }) => {
  
  // useEffect(() => {
  //   checkLoginStatus()
  // }, [])
  
  // const checkLoginStatus = () => {
  //   if (!isLoggedIn() & (location.pathname !== `/all-access/login`)) {
  //     navigate("/all-access/login")
  //     return null
  //   }
  // }

  if (!isLoggedIn() & (location.pathname !== `/${Flags.settings.protectedArea.url}/login`)) {
    if ( 
      prevLocation.includes(`/${Flags.settings.protectedArea.url}/startups/`)
      || prevLocation.includes(`/${Flags.settings.protectedArea.url}/agenda`)
      || prevLocation.includes(`/${Flags.settings.protectedArea.url}/rearview`)
    ) {
      navigate(`/${Flags.settings.protectedArea.url}/login`,
      {
        state: { "prevLocation": prevLocation },
      })
    } else {
      navigate(`/${Flags.settings.protectedArea.url}/login`)
    }
    return null
  }

  return <Component {...rest} />
}

export default AllAccessRoute;